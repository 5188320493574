import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Missed SuperBot 2018 this year? Watch the recaps of all the panel discussions on the future of voice, monetization, big brand strategy, and more!`}</p>
    <h2>{`Opening Keynote Address`}</h2>
    <p>{`Jesse Hull and Arte Merritt open SuperBot 2018 with insights from processing nearly 30 billion messages across Facebook, Alexa, Google Home, Slack, Kik, SMS, and more.`}</p>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/7VPVfqZ2eKw?feature=oembed" width="1200"></iframe>
    <h2>{`The Future of Voice`}</h2>
    <p>{`“Designing #VoiceFirst cuts through all the clutter, unlike with other products. You really get to the point.” – Elaine Lee, Product Design Lead (AI-powered Assistants) at eBay. Hear what this #VoiceFirst expert panel has to say on what the future of voice looks like and how to adjust your roadmap to reflect these changes.`}</p>
    <p><strong parentName="p">{`Speakers:`}</strong><br parentName="p"></br>{`
`}{`Jamie Hirschhorn (Google)`}<br parentName="p"></br>{`
`}{`Noah Vadnai (A+E Networks)`}<br parentName="p"></br>{`
`}{`Dave Grossman (Earplay)`}<br parentName="p"></br>{`
`}{`Elaine Lee (eBay)`}<br parentName="p"></br>{`
`}{`Moderated by: Kevin McLaughlin (The Information)`}</p>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/soX5ntOT_Lc?feature=oembed" width="1200"></iframe>
    <h2>{`Monetization Strategies`}</h2>
    <p>{`The million dollar question in the chatbot industry is “How do you monetize conversational products?” You’ll get the answer in this video.`}</p>
    <p><strong parentName="p">{`Speakers:`}</strong><br parentName="p"></br>{`
`}{`Bree Glaeser (The Mars Agency)`}<br parentName="p"></br>{`
`}{`Lauren Kunze (Pandorabots)`}<br parentName="p"></br>{`
`}{`Peter Buch (Swell)`}<br parentName="p"></br>{`
`}{`Ben Parr (Octane AI)`}<br parentName="p"></br>{`
`}{`Moderated by: Stefan Kojouharov (Chatbot’s Life)`}</p>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/P_JpLPQExKI?feature=oembed" width="1200"></iframe>
    <h2>{`User Acquisition `}{`&`}{` Discovery`}</h2>
    <p>{`How exactly do users discover your chatbot or voice products? Watch the recap to hear our expert panel’s tried-and-true strategies and success stories.`}</p>
    <p><strong parentName="p">{`Speakers:`}</strong><br parentName="p"></br>{`
`}{`Jeremy Thum (Golden State Warriors)`}<br parentName="p"></br>{`
`}{`Jeff Israel (Condé Nast)`}<br parentName="p"></br>{`
`}{`Moderated by: Marlene Jia (TOPBOTS)`}</p>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/q9OnceXWV_A?feature=oembed" width="1200"></iframe>
    <h2>{`Brands Leveraging Conversational Interfaces`}</h2>
    <p>{`If you’re a huge brand with specific expectations from users, how do you maintain tone and consistency in conversational products? Get tips from leading experts about how to build engaging conversational experiences.`}</p>
    <p><strong parentName="p">{`Speakers:`}</strong><br parentName="p"></br>{`
`}{`Mark Mezrich (Viacom)`}<br parentName="p"></br>{`
`}{`Akshaya Murali (Expedia)`}<br parentName="p"></br>{`
`}{`Jason Herndon (RAIN)`}<br parentName="p"></br>{`
`}{`Moderated by: Justina Nguyen (Dashbot)`}</p>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/Je7VQCHPkc0?feature=oembed" width="1200"></iframe>
    <h2>{`Scaling Bots for Customer Service`}</h2>
    <p>{`Customer service is an easy use case to reference when talking about chatbots, but how do you execute and scale successfully? Our leading experts teach you how to build bots for customer service for any industry.`}</p>
    <p><strong parentName="p">{`Speakers:`}</strong><br parentName="p"></br>{`
`}{`Nikhil Mane (Autodesk)`}<br parentName="p"></br>{`
`}{`Vinay Chandra (Intuit)`}<br parentName="p"></br>{`
`}{`Dror Oren (Kasisto)`}<br parentName="p"></br>{`
`}{`Anthony Kunjbehari (LivePerson)`}<br parentName="p"></br>{`
`}{`Moderated by: Karen Hao (Quartz)`}</p>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/sOgvlWeoXgU?feature=oembed" width="1200"></iframe>
    <h2>{`Investor Insights`}</h2>
    <p>{`A power panel of investors discuss trends in the chatbot and voice industry, what interests them in the space, and what they look for in an investment.`}</p>
    <p><strong parentName="p">{`Speakers:`}</strong><br parentName="p"></br>{`
`}{`Jenny Gao (Bessemer Venture Partners)`}<br parentName="p"></br>{`
`}{`Siggi Hindrichs (SamsungNEXT)`}<br parentName="p"></br>{`
`}{`Courtney Nelson (BDMI)`}<br parentName="p"></br>{`
`}{`Austin Arensberg (Scrum Ventures)`}<br parentName="p"></br>{`
`}{`Moderated by: Neel Lilani (Orrick)`}</p>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/zaa9OV2U9Ls?feature=oembed" width="1200"></iframe>
    <h3>{`—`}</h3>
    <h3><span className="s2">{`About `}<a parentName="h3" {...{
          "href": "https://www.dashbot.io/"
        }}><span className="s3">{`Dashbot`}</span></a></span></h3>
    <p><em parentName="p"><span className="s1"><a parentName="em" {...{
            "href": "https://www.dashbot.io/"
          }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools. In addition to traditional analytics like engagement and retention, we provide bot and voice specific metrics including sentiment analysis, conversational analytics, and full chat session transcripts. We also have tools to take action on the data, like our live-person takeover, broadcast messaging, and goal funnels.`}</span></em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      